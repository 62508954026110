import React from "react";
import Pact from "pact-lang-api";

const Context = React.createContext();

const createAPIHost = (chainId) =>
  `https://api.testnet.chainweb.com/chainweb/0.0/testnet04/chain/${chainId}/pact`;
const dumKeyPair = Pact.crypto.genKeyPair();
const createTime = () => Math.round(new Date().getTime() / 1000) - 15;
const dumMeta = (acct) =>
  Pact.lang.mkMeta(acct, "0", 0.00000001, 50000, createTime(), 300);

const id = localStorage.getItem("id");

export class PactStore extends React.Component {
  state = {
    playerId: id,
    playerTable: null,
    round: 1,
    players: [],
    playersData: [],
    chainId: 0,
    hosts: 0,
    currentReqKey: "",
    payoutMatrix: {},
  };

  checkSuccess = async (reqKey) => {
    console.log("in check success");
    console.log(reqKey);
    Pact.fetch
      .poll({ requestKeys: [reqKey] }, createAPIHost("0"))
      .then((res) => {
        if (!res[0]) {
          console.log("pending");
        } else if (res[0].result.status === "success") {
          this.setReqKey("");
          console.log("success");
          window.location.reload();
        } else if (res[0].result.status === "failure") {
          console.log("failed");
          this.setReqKey("");
          alert("TX failed -> please check username and/or wallet signature");
          window.location.reload();
        }
      });
  };

  getCurrentReqKey = () => {
    return this.state.currentReqKey;
  };

  setReqKey = async (reqKey) => {
    await localStorage.setItem("reqKey", reqKey);
  };

  getReqKey = async () => {
    const reqKey = await localStorage.getItem("reqKey");
    return reqKey;
  };

  getPT = () => {
    return this.state.playerTable;
  };

  getCurrentRound = () => {
    if (this.state.playerTable) {
      return this.state.playerTable["rounds"].length - 1;
    } else {
      return 0;
    }
  };

  getPayoutMatrix = async () => {
    const cmd = await Pact.fetch.local(
      {
        pactCode: `(user.pacty-parrots.get-payout-matrix)`,
        keyPairs: dumKeyPair,
        meta: dumMeta("parrot-bank"),
      },
      createAPIHost("0")
    );
    const data = await cmd.result.data;
    await this.setState({ payoutMatrix: data });
    return data;
  };

  startRound = async (round) => {
    if (this.state.playerId !== "" && this.state.playerId) {
      try {
        // const cmd = await Pact.wallet.sign(
        //   //code
        //   `(pacty-parrots.start-round ${JSON.stringify(this.state.playerId)})`,
        //   //envData
        //   {[this.state.playerId]: []},
        //   this.state.playerId,
        //   "0",
        //   100000
        const signCmd = {
          pactCode: `(user.pacty-parrots.start-round ${JSON.stringify(
            this.state.playerId
          )})`,
          // pactCode: `(coin.transfer "sender01" "sender00" 1.0)`,
          caps: [
            Pact.lang.mkCap(
              "Gas capability",
              "description of gas cap",
              "coin.GAS",
              []
            ),
            // Pact.lang.mkCap("transfer capability", "description of transfer cap", "coin.TRANSFER", [this.state.playerId, "sender00", 5.0]),
            Pact.lang.mkCap(
              "transfer capability",
              "description of transfer cap",
              "coin.TRANSFER",
              [this.state.playerId, "parrot-bank", 5.0]
            ),
            Pact.lang.mkCap(
              "Bet capability",
              "Bet for the round",
              "user.pacty-parrots.BET",
              [this.state.playerId]
            ),
          ],
          sender: this.state.playerId,
          gasLimit: 10000,
          chainId: "0",
          ttl: 28800,
          envData: {},
        };
        const cmd = await Pact.wallet.sign(signCmd);
        console.log(cmd);
        const reqKey = await Pact.wallet.sendSigned(cmd, createAPIHost("0"));
        console.log(reqKey.requestKeys[0]);
        await this.setState({ currentReqKey: reqKey.requestKeys[0] });
        this.setReqKey(reqKey.requestKeys[0]);
      } catch (err) {
        alert("you cancelled the TX or you did not have the wallet app open");
        window.location.reload();
      }
    } else {
      alert("Please Log-in!");
      window.location.reload();
    }
  };

  continueRound = async (round) => {
    if (this.state.playerId !== "" && this.state.playerId) {
      // try {
      //   const cmd = await Pact.wallet.sign(
      //     //code
      //     `(pacty-parrots.continue-round ${JSON.stringify(this.state.playerId)})`,
      //     //envData
      //     {[this.state.playerId]: []},
      //     this.state.playerId,
      //     "0",
      //     100000
      //   )
      //   console.log(cmd)
      //   const reqKey = await Pact.wallet.sendSigned(cmd, createAPIHost("0"))
      try {
        const signCmd = {
          pactCode: `(user.pacty-parrots.continue-round ${JSON.stringify(
            this.state.playerId
          )})`,
          // pactCode: `(coin.transfer "sender01" "sender00" 1.0)`,
          caps: [
            Pact.lang.mkCap(
              "Gas capability",
              "description of gas cap",
              "coin.GAS",
              []
            ),
            Pact.lang.mkCap(
              "Bet capability",
              "Bet for the round",
              "user.pacty-parrots.BET",
              [this.state.playerId]
            ),
          ],
          sender: this.state.playerId,
          gasLimit: 10000,
          chainId: "0",
          ttl: 28800,
          envData: {},
        };
        const cmd = await Pact.wallet.sign(signCmd);
        console.log(cmd);
        const reqKey = await Pact.wallet.sendSigned(cmd, createAPIHost("0"));

        console.log(reqKey.requestKeys[0]);
        await this.setState({ currentReqKey: reqKey.requestKeys[0] });
        await this.setReqKey(reqKey.requestKeys[0]);
      } catch (err) {
        alert("you cancelled the TX or you did not have the wallet app open");
        window.location.reload();
      }
    } else {
      alert("Please Log-in!");
      window.location.reload();
    }
  };

  endRound = async (round) => {
    if (this.state.playerId !== "" && this.state.playerId) {
      // try {
      //   const cmd = await Pact.wallet.sign(
      //     //code
      //     `(pacty-parrots.end-round ${JSON.stringify(this.state.playerId)})`,
      //     //envData
      //     {[this.state.playerId]: []},
      //     this.state.playerId,
      //     "0",
      //     100000
      //   )
      //   console.log(cmd)
      //   const reqKey = await Pact.wallet.sendSigned(cmd, createAPIHost("0"))
      try {
        const signCmd = {
          pactCode: `(user.pacty-parrots.end-round ${JSON.stringify(
            this.state.playerId
          )})`,
          // pactCode: `(coin.transfer "sender01" "sender00" 1.0)`,
          caps: [
            Pact.lang.mkCap(
              "Gas capability",
              "description of gas cap",
              "coin.GAS",
              []
            ),
            Pact.lang.mkCap(
              "transfer capability",
              "description of transfer cap",
              "coin.TRANSFER",
              [
                "parrot-bank",
                this.state.playerId,
                this.state.playerTable["rounds"][this.getCurrentRound()][1][
                  "int"
                ],
              ]
            ),
            Pact.lang.mkCap(
              "Bet capability",
              "Bet for the round",
              "user.pacty-parrots.BET",
              [this.state.playerId]
            ),
          ],
          sender: this.state.playerId,
          gasLimit: 10000,
          chainId: "0",
          ttl: 28800,
          envData: {},
        };
        const cmd = await Pact.wallet.sign(signCmd);
        console.log(cmd);
        const reqKey = await Pact.wallet.sendSigned(cmd, createAPIHost("0"));
        console.log(reqKey.requestKeys[0]);
        await this.setState({ currentReqKey: reqKey.requestKeys[0] });
        this.setReqKey(reqKey.requestKeys[0]);
      } catch (err) {
        alert("you cancelled the TX or you did not have the wallet app open");
        window.location.reload();
      }
    } else {
      alert("Please Log-in!");
      window.location.reload();
    }
  };

  getAccountBalance = async () => {
    const cmd = await Pact.fetch.local(
      {
        pactCode: `(coin.get-balance ${JSON.stringify(this.state.playerId)})`,
        keyPairs: dumKeyPair,
        meta: dumMeta("parrot-bank"),
      },
      createAPIHost("0")
    );
    const data = cmd.result.data;
    let balance = "0";
    if (data) {
      try {
        balance = data["decimal"].toString().substring(0, 15);
      } catch {
        balance = data;
      }
    }
    await this.setState({ accountBalance: balance });
  };

  getAllPlayers = async () => {
    const cmd = await Pact.fetch.local(
      {
        pactCode: `(user.pacty-parrots.get-users)`,
        keyPairs: dumKeyPair,
        meta: dumMeta("parrot-bank"),
      },
      createAPIHost("0")
    );
    const data = cmd.result.data;
    if (data) {
      await this.setState({ players: data });
    }
    return data;
  };

  getPlayerTable = async () => {
    const cmd = await Pact.fetch.local(
      {
        pactCode: `(user.pacty-parrots.get-table ${JSON.stringify(
          this.state.playerId
        )})`,
        keyPairs: dumKeyPair,
        meta: dumMeta("parrot-bank"),
      },
      createAPIHost("0")
    );
    // .then(res => {
    //   this.setState({ playerTable: res.data })
    // })
    const data = cmd.result.data;
    if (data) {
      await this.setState({ playerTable: data });
    }
    return data;
  };

  getAllPlayerTables = async () => {
    const l = await this.getAllPlayers();
    const cmd = await Pact.fetch.local(
      {
        pactCode: `(map (user.pacty-parrots.get-table) ${JSON.stringify(l)})`,
        keyPairs: dumKeyPair,
        meta: dumMeta("parrot-bank"),
      },
      createAPIHost("0")
    );
    const data = cmd.result.data;
    if (data) {
      await this.setState({ playersData: data });
    }
  };

  setPlayerId = async (id) => {
    console.log(id);
    localStorage.setItem("id", id);
    await this.setState({ playerId: id });
    console.log(this.state.playerId);
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          startRound: this.startRound,
          continueRound: this.continueRound,
          endRound: this.endRound,
          setPlayerId: this.setPlayerId,
          getAllPlayerTables: this.getAllPlayerTables,
          getPlayerTable: this.getPlayerTable,
          getCurrentRound: this.getCurrentRound,
          checkSuccess: this.checkSuccess,
          getCurrentReqKey: this.getCurrentReqKey,
          getPT: this.getPT,
          getReqKey: this.getReqKey,
          setReqKey: this.setReqKey,
          getPayoutMatrix: this.getPayoutMatrix,
          getAccountBalance: this.getAccountBalance,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Context;
